import { debounce } from '@Core/Composables/helpers.js';
import axios from 'axios';

export default () => ({
	open: false, // Used to open the search bar on mobile
	query: '',
	loading: false,
	livewireLoading: false,
	recentSearches: [],
	results: [],
	totalHits: 0,
	openResults: false, // Used to open the suggestions
	activeIndex: 0, // used to track keyboard navigation in suggestions

	init() {
		// Assign query parameter from URL to the query variable
		const urlParams = new URLSearchParams(window.location.search);
		this.query = urlParams.get('query') || '';

		// Watch for changes in the viewport width and update mobile/desktop view
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				this.open = entry.contentRect.width > 768;
			}
		});
		observer.observe(document.body);

		this.$watch('openResults', (value) => {
			if (value) {
				document.addEventListener('keydown', (e) => {
					if (e.key === 'Escape') {
						this.openResults = false;
					}
				});
			} else {
				document.removeEventListener('keydown', (e) => {
					if (e.key === 'Escape') {
						this.openResults = false;
					}
				});
			}

			this.getRecentSearches();
		});

		// Watch for changes in the query and search
		this.$watch('query', (value) => {
			if (!value.length) {
				this.clearSearch();
			}

			this.getRecentSearches(value);
			this.debounceSearch(value, this);
		});

		// Watch for changes in the results and get the total hits
		this.$watch('results', (value) => {
			this.totalHits = value.length;
		});

		// Avoid closing the result container when the click event happens within it
		document.onclick = (e) => {
			if (this.openResults && !e.target.closest('.suggestions')) {
				this.openResults = false;
			}
		};
	},

	hideSearchbar(currentRoute) {
		const hideFromPages = [
			'homepage',
			'homepage.*',
			'search.index',
			'manufacturers.industry.index',
			'manufacturers.index'
		];

		return hideFromPages.some((page) => {
			return new RegExp(page, 'g').test(currentRoute);
		});
	},

	openSearch(inputId) {
		this.open = true;
		document.body.classList.add('overflow-hidden');
		setTimeout(() => {
			document.querySelector(`#${inputId}`).focus();
		}, 10);
	},

	closeSearch() {
		this.clearSearch();
		this.open = false;
		document.body.classList.remove('overflow-hidden');
	},

	clearSearch() {
		this.query = '';
		this.results = [];
	},

	go(e, direction) {
		const options = document.querySelectorAll('.search li');
		const active = document.querySelector('.search li.active');
		const activeClasses = ['active', 'bg-gray-100'];

		if (options.length < 1) {
			return;
		}

		if (e?.target) {
			e.preventDefault();
		}

		if (active) {
			active.classList.remove(...activeClasses);
		}

		if (direction === 'down') {
			if (this.activeIndex === options.length - 1) {
				this.activeIndex = 0;
			} else {
				this.activeIndex++;
			}
		}

		if (direction === 'up') {
			if (this.activeIndex === 0) {
				this.activeIndex = options.length - 1;
			} else {
				this.activeIndex--;
			}
		}

		options[this.activeIndex].classList.add(...activeClasses);
		options[this.activeIndex].scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'center'
		});
	},

	goTo() {
		if (this.totalHits < 1) return;
		const options = document.querySelectorAll('.search li a');
		window.location.href = `${options[this.activeIndex].getAttribute('href')}`;
	},

	submit(suggestion) {
		if (suggestion) {
			this.query = suggestion;
		}

		if (!this.query) return;

		const path = window.location.pathname;

		if (path === '/gun-values') {
			this.$wire.query = this.query;
			const button = document.getElementById('search-btn');
			button.click();
			this.openResults = false;
		} else {
			this.openResults = false;
			window.location.href = `/gun-values?query=${encodeURIComponent(this.query)}`;
		}
	},

	getRecentSearches(query = '') {
		const storedRecentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
		this.recentSearches = storedRecentSearches.reduce((acc, search) => {
			if (query) {
				const regex = new RegExp(query, 'gi');
				const matchedSearch = search.match(regex);
				if (matchedSearch) {
					acc.push(search.replace(regex, `<em>${query}</em>`));
				}
			} else {
				acc.push(search);
			}
			return acc;
		}, []);
	},

	removeFromRecentSearches(query) {
		const storedRecentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
		const updatedSearches = storedRecentSearches.filter((search) => search !== query);
		localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
		this.getRecentSearches();
	},

	saveRecentSearch(query) {
		let storedRecentSearches = new Set(JSON.parse(localStorage.getItem('recentSearches')) || []);
		if (storedRecentSearches.size >= 5) {
			storedRecentSearches.delete([...storedRecentSearches].pop());
		}
		storedRecentSearches = new Set([query, ...storedRecentSearches]);
		localStorage.setItem('recentSearches', JSON.stringify([...storedRecentSearches]));
	},

	debounceSearch: debounce((query, ctx) => {
		if (!query.length) return;
		ctx.search(query);
	}, 500),

	search(query) {
		this.loading = true;
		axios
			.get(`/search/suggestions?query=${query}`)
			.then((response) => {
				this.results = response.data.hits;
				this.saveRecentSearch(query);

				if (!window.livewireLoading) {
					this.openResults = true;
				}

				setTimeout(() => {
					this.activeIndex = 0;
					this.go();
				}, 10);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
			});
	}
});
